import { TextField } from "@mui/material"
import { FC, useEffect } from "react"
import UseForm from "../../hooks/useForm"
import { SectionI } from "../../models/home"
import { handleChangeFile } from "../../utils/files"
import CustomInputFile from "../Custom/InputFile"

interface Props {
  id: string,
  onChange?: (e: SectionI, id: string) => void,
  value:SectionI,
}

const SectionsItem: FC<Props> = ({ id, onChange, value }) => {

  const { formData, setFormData } = UseForm<SectionI>(value)

  useEffect(() => {
    onChange && onChange(formData, id)
  }, [formData])

  return (
    <div style={{
      display: 'flex',
      flexDirection:'column',marginTop:'2%',
    }}>
      <h3 className="espacio">{formData.titulo}</h3>
      <div style={{ display: 'flex', gap: 5, }}>

        <div className="TitSlug">
        <TextField label="Titulo" value={formData.titulo}
        onChange={e => {
          setFormData(prev => ({ ...prev, titulo: e.target.value }))
        }}  className="txtField"/>
        <TextField label="Slug" value={formData.slug}
        onChange={e => {
          setFormData(prev => ({ ...prev, slug: e.target.value }))
        }} className="txtField"/>
        </div>

        <div className="TitSlug" style={{marginLeft:'5%',}}>
        <TextField label="Color" value={ formData.color}
        onChange={e => {
          setFormData(prev => ({ ...prev, color: e.target.value }))
        }}  className="txtField"/>
        <CustomInputFile label="Imagen (424 x 370px)" img={formData.path_imagen} descubre={true} descubreLabel={true}
            onChange={e => {
              setFormData(prev => ({
                ...prev,
                media:handleChangeFile(e)
              }))
            }} 
          />
        </div>
      </div>
    </div>
  )
}

export default SectionsItem