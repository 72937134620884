import TextField from "@mui/material/TextField"
import { FC } from "react"
import UseForm from "../hooks/useForm"
import { HomeI, SocialLinksI } from "../models/home"

interface Props {
    onChange?: (e:HomeI) => void,
    value: HomeI
}

const SocialLinks: FC<Props> = ({onChange, value}) => {

    const { formData, onChange: onChangeForm } = UseForm<HomeI>(value)

    const handleChange = (e:any) => {

        onChangeForm(e)
        onChange && onChange({
            ...formData,
            [e.target.name]:e.target.value
        })
    }

    return (
        <div className="contenedor">
            <h2 className="titSeccion">REDES SOCIALES</h2>
            <div >
                <TextField label="Facebook" variant='outlined'
                    value={formData.facebook} name="facebook"
                    onChange={handleChange} className="txtField" />
                <TextField label="Tiktok" variant='outlined'
                    value={formData.tiktok} name="tiktok"
                    onChange={handleChange} className="txtField" />
            </div>
            <div>
                <TextField label="Instagram" variant='outlined'
                    value={formData.instagram} name="instagram"
                    onChange={handleChange} className="txtField" />
               
            </div>
        </div>
    )
}

export default SocialLinks