import { HomeI, HomeMediasI } from "../../models/home";
import { urlApi } from "../../utils/strings";

const PATH = 'Secciones/Home'
const url = urlApi(`/images.php`);

const handleUploadMainVideo = async (data: HomeI, medias: HomeMediasI) => {

    if (medias.videoPrincipal) {
        const formData = new FormData();
        formData.append('image', medias.videoPrincipal);
        formData.append('path', PATH);
    
        try {
          const response = await fetch(url, {
            method: 'POST',
            body: formData,
          });
    
          const result = await response.json();
          const rute = `${PATH}/${result.filename}`;
    
          data.videoPrincipal = rute;
          data.pathVideoPrincipal = rute;
    
        } catch (error) {
          console.error('Error:', error);
          alert('Error al subir la imagen');
        }
   
    }

    return data

}

const handleUploadFeaturedProducts = async (data: HomeI) => {

    const uploadPromises = Object.keys(data.productosDestacados).map(async (id) => {

        const media = data.productosDestacados[id].media;
        if (!media) return;
    
        const formData = new FormData();
        formData.append('image', media);
        formData.append('path', "Secciones/Home/ProductosDestacados");
    
        try {
          const response = await fetch(url, {
            method: 'POST',
            body: formData,
          });
    
          const result = await response.json();
          const rute = `${"Secciones/Home/ProductosDestacados"}/${result.filename}`;
    
          data.productosDestacados[id].imagen = rute;
          data.productosDestacados[id].path_imagen = rute;
    
        } catch (error) {
          console.error('Error:', error);
          alert('Error al subir la imagen');
        }
    
        delete data.productosDestacados[id].media;
      });
    
      await Promise.all(uploadPromises);

    return data

}

const handleUploadMedia = async (data: HomeI) => {


    const uploadPromises = Object.keys(data.medias).map(async (id) => {

        const media = data.medias[id].media;
        if (!media) return;
    
        const formData = new FormData();
        formData.append('image', media);
        formData.append('path', "Secciones/Home/Medias");
    
        try {
          const response = await fetch(url, {
            method: 'POST',
            body: formData,
          });
    
          const result = await response.json();
          const rute = `${"Secciones/Home/Medias"}/${result.filename}`;
    
          data.medias[id].imagen = rute;
          data.medias[id].path_imagen = rute;
    
        } catch (error) {
          console.error('Error:', error);
          alert('Error al subir la imagen');
        }
    
        delete data.medias[id].media;
      });
    
      await Promise.all(uploadPromises);

    return data

}

const handleUploadSections = async (data: HomeI) => {

    const uploadPromises = Object.keys(data.secciones).map(async (id) => {

        const media = data.secciones[id].media;
        if (!media) return;
    
        const formData = new FormData();
        formData.append('image', media);
        formData.append('path', "Secciones/Home/Secciones");
    
        try {
          const response = await fetch(url, {
            method: 'POST',
            body: formData,
          });
    
          const result = await response.json();
          const rute = `${"Secciones/Home/Secciones"}/${result.filename}`;
    
          data.secciones[id].imagen = rute;
          data.secciones[id].path_imagen = rute;
    
        } catch (error) {
          console.error('Error:', error);
          alert('Error al subir la imagen');
        }
    
        delete data.secciones[id].media;
      });
    
      await Promise.all(uploadPromises);
    

    return data

}

const handleUploadTiktok = async (data: HomeI) => {

    const uploadPromises = Object.keys(data.tiktok_secciones).map(async (id) => {

        const media = data.tiktok_secciones[id].media;
        if (!media) return;
    
        const formData = new FormData();
        formData.append('image', media);
        formData.append('path', "Secciones/Home");
    
        try {
          const response = await fetch(url, {
            method: 'POST',
            body: formData,
          });
    
          const result = await response.json();
          const rute = `${"Secciones/Home"}/${result.filename}`;
    
          data.tiktok_secciones[id].imagen = rute;
          data.tiktok_secciones[id].path_imagen = rute;
    
        } catch (error) {
          console.error('Error:', error);
          alert('Error al subir la imagen');
        }
    
        delete data.tiktok_secciones[id].media;
      });
    
      await Promise.all(uploadPromises);

      
    return data

}

export const handleUploadMedias = async (data: HomeI, medias: HomeMediasI) => {

    const promises: Promise<any>[] = []

    promises.push(handleUploadMainVideo(data, medias))
    promises.push(handleUploadFeaturedProducts(data))
    promises.push(handleUploadSections(data))
    promises.push(handleUploadTiktok(data))
    promises.push(handleUploadMedia(data))

    await Promise.all(promises)
    console.log(data)

    return data

}