import { urlApi } from "../../utils/strings"
import { ProductoI } from "../../models/productos"

export interface ProductoImagesI {
    imgBackground?: File
}

const handleUploadImages = async (
    data: any, {imgBackground}: ProductoImagesI, id:string
) => {

    if (imgBackground) {
        const formData = new FormData();
        formData.append('image', imgBackground);
        formData.append('path', "Productos"); 
        
        const url = urlApi(`/images.php`)

        try {
            const response = await fetch(url, {
              method: 'POST',
              body: formData,
            });
      
            const result = await response.json();
        
            const rute = `${"Productos"}/${id}/${result.filename}`

            data.img_background = rute
            data.path_img_background = rute

            if (response.ok) {
              alert('Imagen subida con éxito');
              console.log(result);
            } else {
              alert('Error al subir la imagen');
              console.error(result);
            }
          } catch (error) {
            console.error('Error:', error);
            alert('Error al subir la imagen');
          }

    }

    return data

}

export const addProducto = async (data: any, images: ProductoImagesI) => {

    //data = await handleUploadImages(data, images)

    //return await addDoc(collection(db, FS_FIELDS.PRODUCTOS), data)

}

export const updateProducto = async (id: string, data: any, images?: ProductoImagesI, merge = true) => {

  if(images){
    data = await handleUploadImages(data, images, id)
  }


  const url = urlApi(`/productos.php`)

  const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

  console.log(data)
  console.log(response)

}

/*no se usa
export const deleteProducto = async (id: string) => {

    return  null
    return await updateDoc(doc(db, FS_FIELDS.PRODUCTOS, id), {
        activo: false
    })

}*/

export const getProducto = async (id: string) => {

    const response = await fetch(urlApi(`/productos.php?id=${id}`)); 
         
    const { productos, galeria } = await response.json(); 

    let tmp =  productos
    tmp.galeria = galeria.filter((x:any) => x.producto_id == tmp.id)


    return tmp;

}

export const getProductos = async () => {

    const response = await fetch(urlApi('/productos.php')); 
         
    const { productos, galeria } = await response.json(); 
    
    let prod: ProductoI[] = []

    for(let i in productos){
        let tmp =  productos[i]
        tmp.galeria = galeria.filter((x:any) => x.producto_id == tmp.id)
        prod.push(tmp)
    }

    return productos;

}