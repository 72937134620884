import { HomeI, HomeMediasI } from "../../models/home"
import { handleUploadMedias } from "./utils"
import { urlApi } from "../../utils/strings"


export const getHome = async () => {

    const response = await fetch(urlApi(`/home.php`)); 
         
    const {
        home, media_secciones, tiktok_secciones,
        productosDestacados, secciones_secciones
    } = await response.json(); 

    let objHome: HomeI = home[0]
    
    objHome.secciones = secciones_secciones
    objHome.tiktok_secciones = tiktok_secciones
    objHome.medias = media_secciones
    objHome.productosDestacados = productosDestacados

    return objHome;


}

interface UpdateHomeProps {
    data: HomeI,
    medias:HomeMediasI,
}

export const updateHome = async ({data,medias}:UpdateHomeProps) => {

  console.log(data)
    data = await handleUploadMedias(data,medias)

    return await updateHomeData(data)

}

export const updateHomeData = async (data:any) => {

    const url = urlApi(`/home.php`)

    const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const resp = await response.json();
      if (response.ok) {
        console.log("Actualizado");
      } else {
        console.error('Error:');
      }

    return data

}