import React, { ReactNode, createContext, useContext, useState } from "react";
import { UserI, UsersI } from "../models/users";


interface UserContextType {
    users: UsersI;
    setUsers: React.Dispatch<React.SetStateAction<UsersI>>
    user:UserI | null
    setUser: React.Dispatch<React.SetStateAction<UserI | null>>
}

const UserContext = createContext<UserContextType>({
    users: [],
    setUsers: () => { },
    user:{} as any,
    setUser: () => { },
});

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }: { children?: ReactNode }) => {

    const [users, setUsers] = useState<UserI[]>([])
    const [user, setUser] = useState<UserI | null>({} as any)

    return (
        <UserContext.Provider value={{ users, setUsers, user, setUser }}>
            {children}
        </UserContext.Provider>
    );
};
