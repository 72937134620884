import { urlApi, UUIDATE } from "../utils/strings"

export interface BlogImagesI {
    imgThumb?: File
    imgPortada?: File
    imgContenido?: File
    imgDestacada?: File
}

const handleUploadImages = async (
    data: any, {imgContenido, imgPortada, imgThumb, imgDestacada}: BlogImagesI
) => {
    const url = urlApi(`/images.php`)

    if (imgThumb) {
        const formData = new FormData();
        formData.append('image', imgThumb);
        formData.append('path', "Blog"); 
        
        try {
            const response = await fetch(url, {
              method: 'POST',
              body: formData,
            });
      
            const result = await response.json();
            const rute = `${"Blog"}/${result.filename}`
            console.log(rute)

            data.img_thumb = rute
            data.path_img_thumb = rute

          } catch (error) {
            console.error('Error:', error);
            alert('Error al subir la imagen');
          }
    }

    if(imgContenido){

        const formData = new FormData();
        formData.append('image', imgContenido);
        formData.append('path', "Blog"); 
        
        try {
            const response = await fetch(url, {
              method: 'POST',
              body: formData,
            });
      
            const result = await response.json();
            const rute = `${"Blog"}/${result.filename}`
            console.log(rute)

            data.img_contenido = rute
            data.path_img_contenido = rute

          } catch (error) {
            console.error('Error:', error);
            alert('Error al subir la imagen');
          }
    }

    if(imgPortada){
        const formData = new FormData();
        formData.append('image', imgPortada);
        formData.append('path', "Blog"); 
        
        try {
            const response = await fetch(url, {
              method: 'POST',
              body: formData,
            });
      
            const result = await response.json();
            const rute = `${"Blog"}/${result.filename}`
            console.log(rute)

            data.img_portada = rute
            data.path_img_portada = rute

          } catch (error) {
            console.error('Error:', error);
            alert('Error al subir la imagen');
          }
    }

    if(imgDestacada){

        const formData = new FormData();
        formData.append('image', imgDestacada);
        formData.append('path', "Blog"); 
        
        try {
            const response = await fetch(url, {
              method: 'POST',
              body: formData,
            });
      
            const result = await response.json();
            const rute = `${"Blog"}/${result.filename}`
            console.log(rute)

            data.img_destacada = rute
            data.path_img_destacada = rute

          } catch (error) {
            console.error('Error:', error);
            alert('Error al subir la imagen');
          }
    }

    return data

}

export const addBlog = async (data: any, images: BlogImagesI) => {

   
    data = await handleUploadImages(data, images)

    data.id = UUIDATE()
    
    const url = urlApi(`/blogs.php`)

    const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const resp = await response.json();
      if (response.ok) {
        console.log('Valor incrementado:', data.message);
      } else {
        console.error('Error:', data.message);
      }


    return {
        ...data
    }

}

export const updateBlog = async (id: string, data: any, images: BlogImagesI) => {

    data = await handleUploadImages(data, images)
    
    const url = urlApi(`/blogs.php`)

    const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const resp = await response.json();
      if (response.ok) {
        console.log("Actualizado");
      } else {
        console.error('Error:', data.message);
      }

    return data

}

export const deleteBlog = async (id: string) => {

    const url = urlApi(`/blogs.php`)
    
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id })
    });
  
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error en la eliminación');
    }
  
    const data = await response.json();

}

export const getBlog = async (id: string) => {

    const response = await fetch(urlApi(`/blogs.php?id_blog=${id}`)); 
         
    const { blog } = await response.json(); 

    return blog[0];

}

export const getBlogs = async () => {

    const response = await fetch(urlApi('/blogs.php')); 
         
    const { blogs } = await response.json(); 

    return blogs;

}