export const UUID = () => {
    return  btoa(Math.random().toString()).substring(0, 10)
}

export const isNewRegister = (id:string) => id==='nuevo'

export const generateSlug = (str:string) => {
    return str
      .toLowerCase()
      .replace(/[^\w ]+/g,'')
      .replace(/ +/g,'-');
  }

export const urlApi = (api:string) =>{
    return `${process.env.REACT_APP_API_URL}${api}`
}

export const UUIDATE = () => {
    let d = new Date().getTime();
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx".replace(/[xy]/g, c => {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
};