import { FC, useState } from "react"
import { HomeI } from "../../models/home"
import Sections from "../Sections"
import SelectImages from "../SelectImages"
import SocialLinks from "../SocialLinks"
import Tiktok from "../Tiktok"
import useHome from "./useHome"
import SelectMedias from "../SelectMedias"
import CustomInputFile from "../Custom/InputFile"
import { handleChangeFile } from "../../utils/files"
import { Button, CircularProgress } from "@mui/material"
import { uploadFile } from "../../services/storage"
import { updateHomeData } from "../../services/home"
import { urlApi } from "../../utils/strings"

interface Props {
  home: HomeI
}

const Home: FC<Props> = ({ home }) => {

  const {
    onChangeVideoPrincipal,
    onSubmitForm, setFormData
  } = useHome(home)
  const [file, setFile] = useState<File>()
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async () => {

    if (!file) return alert("Tienes que seleccionar un archivo")

    try {

      setIsLoading(true)

      const formData = new FormData();
      formData.append('image', file);
      formData.append('path', "Secciones/Home"); 
      
      let path = ""
      const url = urlApi(`/images.php`)

      try {
          const response = await fetch(url, {
            method: 'POST',
            body: formData,
          });
    
          const result = await response.json();
          const rute = `${"Secciones/Home"}/${result.filename}`
          console.log(rute)

          path = rute

          if (response.ok) {
            alert('Imagen subida con éxito');
            console.log(result);

          } else {
            alert('Error al subir la imagen');
            console.error(result);
          }
        } catch (error) {
          console.error('Error:', error);
          alert('Error al subir la imagen');
        }

      const data = {
        ...home,
        conocenosLink: path,
        paht_conocenosLink: path,
        
      }

      await updateHomeData(data)

      alert("Agregado correctamente")

    } catch (e) {
      console.error("Error ", e)

    } finally {
      setIsLoading(false)
    }

  }

  return (

    <form onSubmit={onSubmitForm} style={{
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    }}>
      <h1>HOME</h1>


      <div style={{
        background: 'white'
      }} className="contenedor">

        <h2 className="titSeccion">Media</h2>

        <SelectMedias value={home.medias}
          title="Imágenes" onChange={e => {
            setFormData(prev => ({ ...prev, medias: e }))
          }} />
      </div>

      <div style={{
        background: 'white'
      }} className="contenedor">

        <h2 className="titSeccion">Conocenos </h2>
        <CustomInputFile
            label="Imagen - Video"
            onChange={e => {
              setFile(handleChangeFile(e))
            }}
            type='videoimg'
            img={home.conocenosLink}
        />
         <Button onClick={handleClick}>
          {!isLoading ? 'Guardar' : <CircularProgress />}
        </Button>
      </div>



      <SocialLinks 
        value={home} 
        onChange={e => {
          setFormData(prev => ({ ...prev, ...e }));
        }} />


      <Sections value={home.secciones}
        onChange={e => {
          setFormData(prev => ({ ...prev, secciones: e }))
        }} title="Descubre todo lo que tenemos para ti" />


      <SelectImages value={home.productosDestacados}
        title="Productos destacados" onChange={e => {
          setFormData(prev => ({ ...prev, productosDestacados: e }))
        }} />


      <Tiktok value={home.tiktok_secciones} onChange={e => {
        setFormData(prev => ({ ...prev, tiktok_secciones: e }))
      }} />


      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', }}>
        <input type="submit" value="Cancelar" className="btnCancelar" />
        <input type="submit" value="Aceptar" className="btnAceptar" />
      </div>
    </form>

  )
}

export default Home