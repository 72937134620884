import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useUserBarrilitoContext } from '../../context/UsersBarrilitoContext';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const labels = ['Maestro', 'Máma', 'Manualidades', 'Papelero', 'Estudiante', 'Empresa', 'Otros'];

const BarsCharts = () => {
    const { usersBarrilito } = useUserBarrilitoContext();

    const interestCounts = labels.map((label) =>
        usersBarrilito.filter(user => user.interes === label).length
    );

    const yAxisValues = [];
    for (let i = 0; i <= 1000; i += 100) {
        yAxisValues.push(i);
    }

    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                min: 0,
                max: 300,
                stepSize: 100,
                beginAtZero: true,
                ticks: {
                    callback: (value:any) => value.toString(),
                    stepSize: 100,
                },
            },
        },
        plugins: {
            legend: {
                position: 'top' as const,
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                label: 'Intereses',
                data: interestCounts,
                backgroundColor: [
                    'rgba(154, 208, 228)',
                    'rgba(246, 233, 102)',
                    'rgba(209, 143, 119)',
                    'rgba(84, 150, 125)',
                ],
                borderColor: [
                    'rgba(154, 208, 228)',
                    'rgba(246, 233, 102)',
                    'rgba(209, 143, 119)',
                    'rgba(84, 150, 125)',
                ],
                borderWidth: 1,
            },
        ],
    };

    return(

        <div style={{width: '700px', height: '550px', marginTop: '100px'}}>
            <Bar options={options} data={data} />

        </div>



    )
        
};

export default BarsCharts;
