import { useState } from 'react'
import { GalleryModalAddProps } from '.'
import { updateProducto } from '../../../services/productos/productos'
import { urlApi, UUID } from '../../../utils/strings'

const useGalleryModalAdd = ({ productId, onSucces }: GalleryModalAddProps) => {

    const [isOpen, setIsOpen] = useState(false)
    const handleOpen = () => setIsOpen(true)
    const handleClose = () => setIsOpen(false)
    const [image, setImage] = useState<File>()

    const [orden, setOrden] = useState<number>()


    const handleAdd = async () => {

        if (!image) return alert("No hay imagen seleccionada")


           
        const formData = new FormData();
        formData.append('image', image);
        formData.append('path', `${"Productos"}/${productId}`); 
        
        const url = urlApi(`/images.php`)
        let path = "";
        try {
            const response = await fetch(url, {
                method: 'POST',
                body: formData,
            });
        
            const result = await response.json();
            const rute = `${"Productos"}/${productId}/${result.filename}`

            path = rute

            if (response.ok) {
                alert('Imagen subida con éxito');
                console.log(result);
            } else {
                alert('Error al subir la imagen');
                console.error(result);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error al subir la imagen');
        }
        

        try {

            const data = {
                galeria: {
                    [UUID()]: {
                        imagen: path,
                        path_imagen: path,
                        producto_id:productId,
                        orden: orden || 0,
                        fechaRegistro: new Date(),
                    } 
                }
            }
            
            //return data
            await updateProducto(productId, data)
            onSucces && onSucces(data)
            handleClose()


        } catch (e) {

            console.error("Error al guardar ", e)

        }

    }

    return {
        isOpen,
        handleClose,
        handleOpen,
        setImage,
        orden,
        setOrden,
        image,
        handleAdd,
    }

}

export default useGalleryModalAdd
