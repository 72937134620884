import Button from '@mui/material/Button';
import { GridColDef } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { useDescargablesContext } from '../../../context/DescargablesContext';
import { deleteDescargable } from '../../../services/descargable';
import ExternalLink from '../../ExternalLink';
import ImgThumb from '../../ImgThumb';

const useVideoTable = () => {

    const navigate = useNavigate()
    const {setDescargables} = useDescargablesContext()

    const handleDelete = async (id: string) => {

        if (!window.confirm('¿Borrar Descargable?')) return

        await deleteDescargable(id)
        setDescargables(prev=>prev.filter(e=>e.id!==id))
        alert("Descargable eliminado")

    }

    function formatDateToYear(date: Date): string {
        return date.toLocaleString("es-MX", { day:'2-digit', month: "2-digit",  year: "numeric"  });
    }

    const columns: GridColDef[] = [
        {
            field: 'fechaRegistro',
            headerName: 'Registro',
            width: 100,
            //valueFormatter: (params) => formatDateToYear(params.value?.toDate())
        },
        {
            field: 'titulo',
            headerName: 'Titulo',
            width: 200
        },
        {
            field: 'descripcion_breve',
            headerName: 'Descripción Breve',
            width: 200
        }, {
            field: 'categorias',
            headerName: 'Categorias',
            width: 200
        },
        {
            field: 'favoritos',
            headerName: 'Favoritos',
            width: 200
        },
        {
            field: 'descargados',
            headerName: 'Descargas',
            width: 200
        },
        {
            field: 'path_img_thumb',
            headerName: 'Imagen Thumb',
            width: 200,
            renderCell: (e) => <ImgThumb src={e.value} />
        },
        {
            field: 'descargar_pdf',
            headerName: 'PDF',
            width: 200,
            renderCell: (e) => <ExternalLink src={e.value} />

        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            width: 200,
            renderCell: (e) => {
                return <div style={{
                    display: 'flex',
                    gap: 4,
                }}>
                    <Button variant="contained" style={{ backgroundColor: 'transparent' }} onClick={() => {
                        navigate(`/descargable/${e.id}`)
                    }}>
                        <img src={'./assets/img/editar.png'} alt="Button Image" style={{ width: '20px', height: '20px' }} />
                    </Button>
                    <Button variant="contained" style={{ backgroundColor: 'transparent' }}
                        onClick={() => {
                            handleDelete(e.id as string)
                        }}>
                        <img src={'./assets/img/eliminar.png'} alt="Button Image" style={{ width: '20px', height: '20px' }} />
                    </Button>
                </div>
            }
        },
    ];

    return {
        columns
    }

}

export default useVideoTable