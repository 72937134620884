import { collection, getDocs, orderBy, query, where } from "firebase/firestore"

import { docsToArray } from "../utils/arrays"
import { db } from "../firebase/firebase"

export const getUsersBarrilito = async () => {

    const userRef = collection(
        db, "Usuarios",
    )

    const snap = await getDocs(
        query(
            userRef,
            where('activo', '==', true),
            where('rol', 'in', ['Usuario']),
            orderBy("fechaRegistro", "desc")
        )
    )

    return docsToArray(snap)

}