export const encrypt = (string:string, shift:number) => {
  let result = '';
  for (let i = 0; i < string.length; i++) {
      result += String.fromCharCode(string.charCodeAt(i) + shift);
  }
  return result;
}

export const decrypt = (string:string, shift:number)=> {
  let result = '';
  for (let i = 0; i < string.length; i++) {
      result += String.fromCharCode(string.charCodeAt(i) - shift);
  }
  return result;
}