import { Timestamp } from "firebase/firestore";
import { UserI } from "../../../models/users";

export const USER_BASE: UserI = {
    activo: true,
    nombre: "",
    email: "",
    password: "",
    rol: "",
    fechaRegistro: new Date(),
    id: "",
};