import { useVideoContext } from '../../../context/VideoContext'
import CustomTable from '../../Custom/Table'
import useVideoTable from './useVideoTable'


const VideoTable = () => {

    const { columns } = useVideoTable()
    const {videos} = useVideoContext()

    const videosDate = [...videos].sort((a, b) => new Date(b.fechaRegistro).getTime() - new Date(a.fechaRegistro).getTime());

    return <CustomTable rows={videosDate} columns={columns} />
        
}

export default VideoTable