import { ContactanosI, NosotrosI } from "../models/nosotros"
import { urlApi } from "../utils/strings"

export interface NosotrosFilesI {
    imgThumb?: File
}


export const getNosotros = async () => {

    const response = await fetch(urlApi(`/nosotros.php`)); 
         
    const {nosotros, distribuidores} = await response.json(); 
    let objNosotros: NosotrosI = nosotros[0]
    
    objNosotros.distribuidores = {
        imagenes: distribuidores,
        texto1:  "<p><br></p>",
        texto2:  "<p><br></p>",
        texto3:  "<p><br></p>"
    }

    return objNosotros;

}

interface UpdateNosotrosProps {
    data:NosotrosI
}

const handleUploadDistribuidoresImagenes = async ({ data }: UpdateNosotrosProps) => {

  const uploadPromises = Object.keys(data.distribuidores.imagenes).map(async (id) => {

    const media = data.distribuidores.imagenes[id].media;
    if (!media) return;

    const formData = new FormData();
    formData.append('image', media);
    formData.append('path', "Secciones/Nosotros/Nosotros");

    const url = urlApi(`/images.php`);
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      const rute = `${"Secciones/Nosotros/Nosotros"}/${result.filename}`;

      data.distribuidores.imagenes[id].imagen = rute;
      data.distribuidores.imagenes[id].path_imagen = rute;

    } catch (error) {
      console.error('Error:', error);
      alert('Error al subir la imagen');
    }

    // Elimina la propiedad 'media' después de subir la imagen
    delete data.distribuidores.imagenes[id].media;
  });

  // Espera a que todas las promesas de carga de imágenes se completen
  await Promise.all(uploadPromises);

  return data;
};


const handleUploadMedias = async ({data}: UpdateNosotrosProps) => {

    //const promises: Promise<any>[] = []
    const url = urlApi(`/images.php`)

    if(data.media){
        const formData = new FormData();
        formData.append('image', data.media);
        formData.append('path', 'Secciones/Nosotros'); 
        
        try {
            const response = await fetch(url, {
              method: 'POST',
              body: formData,
            });
      
            const result = await response.json();
            const rute = `${'Secciones/Nosotros'}/${result.filename}`
            console.log(rute)

            data.imagen = rute
            data.pathImagen = rute

          } catch (error) {
            console.error('Error:', error);
            alert('Error al subir la imagen');
          }

        delete data.media

    }

    data = await (handleUploadDistribuidoresImagenes({data}))

    return data

}

export const updateNosotros = async ({data}:UpdateNosotrosProps) => {

    data = await handleUploadMedias({data})

    const url = urlApi(`/nosotros.php`)

    const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const resp = await response.json();
      if (response.ok) {
        console.log("Actualizado");
      } else {
        console.error('Error:');
      }

    return data
}

/*export const getContactanos = async () => {
    const snap = await getDoc(doc(db, FS_FIELDS.SECCIONES, FS_FIELDS.CONTACTANOS))

    return snap.data() as ContactanosI
}*/