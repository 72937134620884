import { Box, Button, CircularProgress } from '@mui/material'
import { FC, useState } from 'react'
import { HomeI } from '../../models/home'
import { updateHomeData } from '../../services/home'
import { uploadFile } from '../../services/storage'
import { handleChangeFile } from '../../utils/files'
import CustomInputFile from '../Custom/InputFile'
import { mediaFilter } from '../../utils/image'
import { urlApi } from '../../utils/strings'

interface Props {
  home: HomeI
}

const Catalogo: FC<Props> = ({ home }) => {

  const [file, setFile] = useState<File>()
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async () => {

    if (!file) return alert("Tienes que seleccionar un archivo")

    try {

      setIsLoading(true)

      const formData = new FormData();
      formData.append('image', file);
      formData.append('path', "Secciones/Home"); 
      
      let path = ""
      const url = urlApi(`/images.php`)

      try {
          const response = await fetch(url, {
            method: 'POST',
            body: formData,
          });
    
          const result = await response.json();
          const rute = `${"Secciones/Home"}/${result.filename}`
          console.log(rute)

          path = rute

          if (response.ok) {
            alert('Imagen subida con éxito');
            console.log(result);

          } else {
            alert('Error al subir la imagen');
            console.error(result);
          }
        } catch (error) {
          console.error('Error:', error);
          alert('Error al subir la imagen');
        }

      const data = {
        ...home,
        url_catalogo: path,
        path_url_catalogo: path,
        
      }

      await updateHomeData(data)

      alert("Pdf subido correctamente")

    } catch (e) {

      console.error("Error ", e)

    } finally {
      setIsLoading(false)
    }

  }

  return (
    <>
      <Box style={{
        display: 'flex'
      }}>
        <CustomInputFile label='Catalogo PDF' type='pdf' 
        img={mediaFilter({path:home.path_url_catalogo})}
          onChange={e => {
            setFile(handleChangeFile(e))
          }} />
        <Button onClick={handleClick}>
          {!isLoading ? 'Guardar' : <CircularProgress />}
        </Button>
      </Box>
    </>
  )
}

export default Catalogo