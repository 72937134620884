import { Container, CssBaseline, Box, Avatar, Typography, TextField, Button } from '@mui/material'
import React, { useState } from 'react'
import { getUserSQL } from '../../../services/users'
import { useNavigate } from 'react-router-dom';


const Login = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    })

    const handleLogin = async (e: any) => {

        e.preventDefault()
        const us = await getUserSQL(formData.email, formData.password)

        if(us.error){
            return alert("Usuario no registrado o error en contraseña")
        }
        
        if(us.rol == "Administrador" || us.rol == "Editor"){
            localStorage.setItem('user', JSON.stringify(us));
            window.location.reload()
        }
        else{
            return alert("Usuario no registrado o error en contraseña")
        }


        //navigate('/home');
        //window.location.href = '/home';

    }

    return (
    <Container component="main" maxWidth="xs" style={{marginTop: '10%'}}>
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <img src="./assets/img/logoFondoBlanco.png" alt="logo"  width={383} height={67}/>
                <h1 style={{fontFamily: 'Tesla', fontWeight: 500, fontSize:'42px', color: '#000', marginTop:'25%'}}>Iniciar sesión</h1>
                <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 } }  style={{ display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Correo electrónico"
                        name="email"
                        autoComplete="email"
                        onChange={e => {
                            setFormData(f => ({ ...f, email: e.target.value }))
                        }}
                        autoFocus
                        style={{width:'450px', height:'40px', fontFamily: 'Work Sans', fontWeight: 500, fontSize:'16px', color: '#626262', borderRadius:'15px'
                        }}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={e => {
                            setFormData(f => ({ ...f, password: e.target.value }))
                        }}
                        style={{marginTop:'16px',width:'450px', height:'40px', fontFamily: 'Work Sans', fontWeight: 500, fontSize:'16px', color: '#626262'
                    }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        style={{background:'#000',
                        width: '134px',
                        height: '50px',
                    borderRadius:'10px'}}
                        
                    >
                        Entrar
                    </Button>
                </Box>
            </Box>

        </Container>
  )
}

export default Login