import React, { FC } from 'react'
import ExternalLink from '../../ExternalLink'
import ImgThumb from '../../ImgThumb'
import VideoThumb from '../../VideoThumb'
import { ACCEPT_FILES, MB_IN_BYTES } from './constants'
import { CustomInputFilesProps } from './props'
import ImgVideo from '../../ImgVideo'

const CustomInputFile: FC<CustomInputFilesProps> = ({
    onChange, label, img, descubre, descubreLabel,
    type = 'img'
}) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
    
            if (file) {
                const { size, type } = e.target.files[0];
                let limitMB;
    
                // Establecer límite en función del tipo de archivo
                if (type === 'application/pdf') {
                    limitMB = 200;
                } else {
                    limitMB = type === 'image' ? 1 : 15;
                }
    
                if (size >= limitMB * MB_IN_BYTES) {
                    return alert(`El límite del archivo es de ${limitMB} MB`);
                }
            }
        }
    
        onChange && onChange(e);
    };


    return (
        <div style={{ display: 'flex', width: '50%', marginBottom: '2%' }}>

            <div className={descubre ? "imagenCont" : "imagenContVid"}>

                <div style={{ marginRight: '5%', width: '450px' }} className='FileLabel'>

                    <label>{label} </label>

                    <input type="file" accept={ACCEPT_FILES[type]}
                        onChange={handleChange} style={{}} />
                </div>

                {type === 'img' && <ImgThumb src={img} />}
                {type === 'video' && <VideoThumb src={img} />}
                {type === 'pdf' && <ExternalLink src={img} />}
                {type === 'videoimg' && <ImgVideo src={img} />}

            </div>

        </div>
    )
}

export default CustomInputFile