import Button from '@mui/material/Button';
import { GridColDef } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { useUserContext } from '../../../context/UserContext';
import { deleteUser } from '../../../services/users';

const useUsersTable = () => {

    const navigate = useNavigate()
    const { setUsers } = useUserContext()

    const handleDelete = async (id: string) => {

        if (!window.confirm('¿Borrar Admin?')) return

        try {

            await deleteUser(id)
            setUsers(prev => prev.filter(e => e.id !== id))
            alert("Admin eliminado")

        } catch (e) {

            alert("Ocurrió un error inesperado")
            console.log(e)

        }

    }

    const columns: GridColDef[] = [

        {
            field: 'nombre',
            headerName: 'Nombre',
            width: 200
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 200
        },
        {
            field: 'rol',
            headerName: 'Rol',
            width: 200
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            width: 200,
            renderCell: (e) => {
                return <div style={{
                    display: 'flex',
                    gap: 4,
                }}>
                    <Button variant="contained" style={{ backgroundColor: 'transparent' }} onClick={() => {
                        navigate(`/admin/${e.id}`)
                    }}>  <img src={'./assets/img/editar.png'} alt="Button Image" style={{ width: '20px', height: '20px' }} />
                    </Button>
                    <Button variant="contained" style={{ backgroundColor: 'transparent' }} onClick={() => {
                        handleDelete(e.id as string)
                    }}>
                        <img src={'./assets/img/eliminar.png'} alt="Button Image" style={{ width: '20px', height: '20px' }} />
                    </Button>
                </div>
            }
        },
    ];

    return {
        columns
    }

}

export default useUsersTable