import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import BentoTwoToneIcon from '@mui/icons-material/BentoTwoTone';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloudDownloadTwoToneIcon from '@mui/icons-material/CloudDownloadTwoTone';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import MenuIcon from '@mui/icons-material/Menu';
import OndemandVideoTwoToneIcon from '@mui/icons-material/OndemandVideoTwoTone';
import AdminPanelSettingsTwoToneIcon from '@mui/icons-material/AdminPanelSettingsTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { FC, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../context/UserContext';
import PollTwoToneIcon from '@mui/icons-material/PollTwoTone';
import useLogout from './Auth/useLogout';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

interface Props {
    children?: ReactNode
}



const Dashboard: FC<Props> = ({ children }) => {

    const [open, setOpen] = useState(true);
    const toggleDrawer = () => setOpen(!open)
    const {setUser, user} = useUserContext()


    const logout = () =>{
        localStorage.removeItem('user')
        //window.location.href = '/login';
        window.location.reload()

        setUser(null)
    }

    const navigate = useNavigate()


    return (
        <Box sx={{ display: 'flex', marginTop: '0 !important' }} >

            <AppBar position="absolute" open={open} style={{ backgroundColor: '#000', display: 'none', }}>
                <Toolbar sx={{ pr: '24px' }} >
                    <IconButton
                        style={{ color: 'black' }}
                        edge="start"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon style={{ filter: 'invert(1)' }} />
                    </IconButton>

                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        <img src="./assets/img/logo_barrilito.png" alt="logo" style={{ height: '50px' }} />
                    </Typography>
                </Toolbar>
            </AppBar>



            <Drawer variant="permanent" open={open} >
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                    style={{ background: '#000', color: '#FFF' }} >

                    <img src="./assets/img/logo_barrilito.png" alt="logo" style={{ height: '50px' }} />

                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon style={{ filter: 'invert(1)' }} />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component='nav' style={{ background: '#000', minHeight: '91.9%', color: '#FFF' }} >

                    <ListItemButton onClick={() => { navigate("/") }}>
                        <ListItemIcon>
                            <HomeTwoToneIcon style={{ filter: 'invert(1)' }} />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItemButton>

                    <ListItemButton onClick={() => { navigate("/blog") }}>
                        <ListItemIcon>
                            <ArticleTwoToneIcon style={{ filter: 'invert(1)' }} />
                        </ListItemIcon>
                        <ListItemText primary="Blog" />
                    </ListItemButton>

                    <ListItemButton onClick={() => { navigate("/video") }}>
                        <ListItemIcon>
                            <OndemandVideoTwoToneIcon style={{ filter: 'invert(1)' }} />
                        </ListItemIcon>
                        <ListItemText primary="Video" />
                    </ListItemButton>

                    <ListItemButton onClick={() => { navigate("/descargable") }}>
                        <ListItemIcon>
                            <CloudDownloadTwoToneIcon style={{ filter: 'invert(1)' }} />
                        </ListItemIcon>
                        <ListItemText primary="Descargables" />
                    </ListItemButton>

                    <ListItemButton onClick={() => { navigate("/productos") }}>
                        <ListItemIcon>
                            <BentoTwoToneIcon style={{ filter: 'invert(1)' }} />
                        </ListItemIcon>
                        <ListItemText primary="Productos" />
                    </ListItemButton>

                    <ListItemButton onClick={() => { navigate("/nosotros") }}>
                        <ListItemIcon>
                            <PeopleAltTwoToneIcon style={{ filter: 'invert(1)' }} />
                        </ListItemIcon>
                        <ListItemText primary="Nosotros" />
                    </ListItemButton>

                    {
                        user?.rol === 'Administrador' && <ListItemButton onClick={() => { navigate("/admin") }}>
                            <ListItemIcon>
                                <AdminPanelSettingsTwoToneIcon  style={{ filter: 'invert(1)' }} />
                            </ListItemIcon>
                            <ListItemText primary="Administradores" />
                        </ListItemButton>
                    }

                    {
                        user?.rol === 'Administrador' && <ListItemButton onClick={() => { navigate("/usuarios") }}>
                            <ListItemIcon>
                                <PersonOutlineTwoToneIcon style={{ filter: 'invert(1)' }} />
                            </ListItemIcon>
                            <ListItemText primary="Usuarios" />
                        </ListItemButton>
                    }

                    {
                        user?.rol === 'Administrador' && <ListItemButton onClick={() => { navigate("/graficas") }}>
                            <ListItemIcon>
                                <PollTwoToneIcon style={{ filter: 'invert(1)' }} />
                            </ListItemIcon>
                            <ListItemText primary="Graficas" />
                        </ListItemButton>
                    }

                    <ListItemButton onClick={() => { logout() }}>
                        <ListItemIcon>
                            <ExitToAppTwoToneIcon style={{ filter: 'invert(1)' }} />
                        </ListItemIcon>
                        <ListItemText primary="Cerrar Sesion" />
                    </ListItemButton>

                </List>
            </Drawer>


            <Box component="main" sx={{ backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900], flexGrow: 1, height: '100vh', overflow: 'auto', }} >
                <Container maxWidth="xl" sx={{ mt: -1, mb: 10 }}>
                    {children}
                </Container>
            </Box>
        </Box>
    );
}

export default Dashboard