import { useDescargablesContext } from '../../../context/DescargablesContext'
import CustomTable from '../../Custom/Table'
import useDescargablesTable from './useDescargablesTable'


const DescargablesTable = () => {


    const { columns } = useDescargablesTable()
    const { descargables } = useDescargablesContext()

    const descargablesDate = [...descargables].sort((a, b) => new Date(b.fechaRegistro).getTime() - new Date(a.fechaRegistro).getTime());


    return <CustomTable rows={descargablesDate} columns={columns} />
}

export default DescargablesTable