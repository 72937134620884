import { useUserContext } from '../../../context/UserContext'
import CustomTable from '../../Custom/Table'
import useUsersTable from './useUsersTable'

const AdminTable = () => {

  const { columns } = useUsersTable()
  const {users} = useUserContext()

  return <CustomTable rows={users.filter(e => e.rol == "Administrador" || e.rol == "Editor")} columns={columns} />
}

export default AdminTable