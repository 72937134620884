import { useState } from "react";
import { Button, Checkbox, FormControlLabel, ImageListItem, ImageListItemBar} from "@mui/material";
import { deleteField } from "firebase/firestore";
import { FC } from "react";
import { ImageItemI } from "../../models/home";
import { updateProducto } from "../../services/productos/productos";
import { removeFile } from "../../services/storage";
import { mediaFilter } from "../../utils/image";

interface Props {
    id: string;
    item: ImageItemI;
    productId: string;
    onDelete?: (id: string) => void;
    onToggleSelect: (id: string) => void;
}

const GalleryItem: FC<Props> = ({id, item, productId, onDelete, onToggleSelect}) => {

    const [isChecked, setIsChecked] = useState(false);

    const handleDelete = async () => {
        try {

            const galeria = {
                ...item, delete:true
            }
            const data = {
               galeria:{
                "1":galeria
               },
            };

            if(!window.confirm('¿Borrar imagen?')) return
            
            await Promise.allSettled([
                updateProducto(productId, data, undefined, false),
                //removeFile(item.path_imagen),
            ]);

            onDelete && onDelete(id);

            alert("Imagen eliminada correctamente");
        } catch (e) {
            console.log("Error", e);
        }
    };

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const checked = event.target.checked;
        setIsChecked(checked);
        onToggleSelect(id);
    };

    return (
        <>
            <ImageListItem style={{ border: "1px solid", borderColor: '#D3D3D3' }}>

                <img src={`${mediaFilter({path:item.path_imagen})}?w=164&h=164&fit=crop&auto=format`} srcSet={`${mediaFilter({path:item.path_imagen})}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`} alt={"Imagen producto"} loading="lazy" style={{ maxWidth: "178px", maxHeight: "178px", margin: 'auto' }} />

                <div style={{ display: "flex", margin: 'auto' }} >
                    <ImageListItemBar position="below" title={item.orden} style={{ marginTop: "5px", marginLeft: '5px' }} />

                    <FormControlLabel style={{ margin: 'auto', marginLeft: '5px'}} control={ <Checkbox checked={isChecked} onChange={handleCheckboxChange} /> }label="" />

                    <Button onClick={handleDelete}  >
                        <img src={"/assets/img/eliminar.png"} alt="Button Image" style={{ width: "20px", height: "20px"}} />
                    </Button>
                    
                </div>
            </ImageListItem>
        </>
    );
};

export default GalleryItem;
