import { Box, TextField, TextareaAutosize } from '@mui/material'
import { FC, useEffect } from 'react'
import UseForm from '../../../hooks/useForm'
import { NosotrosI } from '../../../models/nosotros'

interface Props {
  value:NosotrosI
  onChange?: (e:NosotrosI) => void,
}

const CorporativoSection: FC<Props> = ({value, onChange}) => {

  const {formData, onChange:onChangeForm} = UseForm(value)

  useEffect(() => {
    onChange && onChange(formData)
  }, [formData])
  
  return (

    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
        background: '#fff',
        paddingBottom: '10%'
      }}>
      <h1>Corporativo</h1>
      <TextareaAutosize
        aria-label="empty textarea"
        placeholder="Direccion"
        minRows={20}
        style={{ width: '100%', padding: '5px' }}
        required
        name="direccion"
        onChange={onChangeForm}
        value={formData.direccion}
      />

      <TextField
        label="Telefono" variant='outlined'
        style={{ background: '#fff' }} required
        onChange={onChangeForm} name="telefono"
        value={formData.telefono} />

      < TextField
        label="Email" variant='outlined'
        style={{ background: '#fff' }} required
        onChange={onChangeForm} name="email"
        value={formData.email} />
    </Box>

  )
}

export default CorporativoSection