import { Box, TextField } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import useForm from '../../../hooks/useForm'

interface Props{
  value: string,
  onChange?: (e:string) => void,
}

const ContactanosSection:FC<Props> = ({ value, onChange }) => {

  const [email, setEmail] = useState(value);

  useEffect(() => {
    onChange && onChange(email); 
  }, [email]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
        background: '#fff',
        paddingBottom: '10%'
      }}
    >
      <h1>Contáctanos</h1>

      <TextField
        label="Email"
        variant="outlined"
        name="email"
        style={{ background: '#fff' }}
        required
        value={email}
        onChange={handleChange}
      />
    </Box>
  )
}

export default ContactanosSection