import { urlApi, UUIDATE } from "../utils/strings"

export interface DescargableFilesI {
    imgThumb?: File
    uploadPDF?: File
}

const handleUploadImages = async (
    data: any, {imgThumb, uploadPDF}: DescargableFilesI
) => {

    if (imgThumb) {
        const formData = new FormData();
        formData.append('image', imgThumb);
        formData.append('path', "Descargables"); 
        
        const url = urlApi(`/images.php`)

        try {
            const response = await fetch(url, {
              method: 'POST',
              body: formData,
            });
      
            const result = await response.json();
            const rute = `${"Descargables"}/${result.filename}`
            console.log(rute)

            data.img_thumb = rute
            data.path_img_thumb = rute

            if (response.ok) {
              alert('Imagen subida con éxito');
              console.log(result);

            } else {
              alert('Error al subir la imagen');
              console.error(result);
            }
          } catch (error) {
            console.error('Error:', error);
            alert('Error al subir la imagen');
          }
    }

    if(uploadPDF){
        const formData = new FormData();
        formData.append('image', uploadPDF);
        formData.append('path', "Descargables"); 
        
        const url = urlApi(`/images.php`)

        try {
            const response = await fetch(url, {
                method: 'POST',
                body: formData,
            });
        
            const result = await response.json();
            const rute = `${"Descargables"}/${result.filename}`

            data.descargar_pdf = rute
            data.path_descargar_pdf = rute

            if (response.ok) {
                alert('Imagen subida con éxito');
                console.log(result);
                

            } else {
                alert('Error al subir la imagen');
                console.error(result);
            }
            } catch (error) {
                console.error('Error:', error);
                alert('Error al subir la imagen');
            }
    }

    return data

}

export const addDescargable = async (data: any, images: DescargableFilesI) => {
   
    data = await handleUploadImages(data, images)

    data.id = UUIDATE()
    
    const url = urlApi(`/descargables.php`)

    const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const resp = await response.json();
      if (response.ok) {
        console.log('Valor incrementado:', data.message);
      } else {
        console.error('Error:', data.message);
      }


    return {
        ...data
    }

}

export const updateDescargable = async (id: string, data: any, images: DescargableFilesI) => {

    data = await handleUploadImages(data, images)
    console.log(data)
    
    const url = urlApi(`/descargables.php`)

    const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const resp = await response.json();
      if (response.ok) {
        console.log("Actualizado");
      } else {
        console.error('Error:', data.message);
      }

    return data

}

export const deleteDescargable = async (id: string) => {

  const url = urlApi(`/descargables.php`)
    
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
        'Content-Type': 'application/json'
    },
    body: JSON.stringify({ id }) // Envía el ID en el cuerpo de la solicitud
  });

  if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Error en la eliminación');
  }

  const data = await response.json();

}

export const getDescargable = async (id: string) => {

    const response = await fetch(urlApi(`/descargables.php?id=${id}`)); 
         
    const { descargable } = await response.json(); 
    
    return descargable[0];
}

export const getDescargables = async () => {

    const response = await fetch(urlApi('/descargables.php')); 
         
    const { descargables } = await response.json(); 

    return descargables;


}