import TextareaAutosize from '@mui/base/TextareaAutosize'
import { Box, Button, Checkbox, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { VideoI } from '../../../models/video'
import CustomInputFile from '../../Custom/InputFile'
import useVideoAdd from './useVideoAdd'


interface Props {
  video?: VideoI,
}

const VideoAdd: FC<Props> = ({ video }) => {

  const { onChange, handleSubmit, formData, setImages, setFormData } = useVideoAdd(video)
  const navigate = useNavigate()

  return (
    <>
  
      <Box component='form' onSubmit={handleSubmit} style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
        background: '#fff',
        padding: '4%',
        paddingBottom: '10%'
      }}>

        <h1>Video</h1>

        <TextField label="Titulo" variant='outlined' value={formData.titulo} name="titulo" onChange={onChange} style={{ background: '#fff' }} required />

        <TextField label="Descripcion Breve" variant='outlined' value={formData.descripcion_breve} name="descripcion_breve" onChange={onChange} style={{ background: '#fff' }} required />

        <TextField label="URL Video" variant='outlined' value={formData.url_vimeo} name="url_vimeo" onChange={onChange} style={{ background: '#fff' }} required />

        <p style={{ marginLeft: '5px', marginTop: '-5px'}}>EJ: &nbsp;   https://player.vimeo.com/video/503114709</p> 

        <FormControl>
          <InputLabel >Categorias</InputLabel>
          <Select label="Orden" name="categorias" onChange={onChange} value={formData.categorias}>
            <MenuItem value={'Manualidades educativas'} >Manualidades educativas</MenuItem>
            <MenuItem value={'Manualidades divertidas'}>Manualidades divertidas</MenuItem>
            <MenuItem value={'Manualidades sensoriales'}>Manualidades sensoriales</MenuItem>
            <MenuItem value={'Manualidades con material recliclado'}>Manualidades con material reciclado</MenuItem>
            <MenuItem value={'Manualidades de papel'}>Manualidades de papel</MenuItem>
            <MenuItem value={'Regalos'}>Regalos</MenuItem>
            <MenuItem value={'Barritips'}>Barritips</MenuItem>
          </Select>
        </FormControl>


        <TextareaAutosize
          aria-label="empty textarea"
          placeholder="Contenido"
          minRows={20}
          style={{ width: '100%', padding: '5px' }}
          required
          value={formData.contenido}
          name="contenido" onChange={onChange}

        />

        <FormControl>
          <InputLabel>Destacado</InputLabel>

          <Checkbox checked={formData.destacado} onChange={() => {
            setFormData(prev => ({
              ...prev,
              destacado: !prev.destacado
            }))
          }} />

        </FormControl>

        <FormControl>
          <InputLabel>Restringido</InputLabel>

          <Checkbox checked={formData.restringido} onChange={() => {
              setFormData(prev=>({
                ...prev,
                restringido: !prev.restringido
              }))
            }} />
        </FormControl>

        <CustomInputFile label='Imagen thumb (848 x 566px)' onChange={e => {
          setImages(prev => ({
            ...prev,
            imgThumb: e.target.files ? e.target.files[0] : undefined
          }))
        }} img={formData.path_img_thumb} />

        <CustomInputFile label='Imagen Destacada (696 x 524px)' onChange={e => {
          setImages(prev => ({
            ...prev,
            imgDestacada: e.target.files ? e.target.files[0] : undefined
          }))
        }} img={formData.path_img_destacada} />




        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', }}>
          <Button color="success" variant="contained" type='submit' style={{
            background: '#000',
            fontFamily: 'WorkSans',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '16px',
            textAlign: 'center',
            textTransform: 'uppercase',
            color: '#FFF',
            padding: '17px 62px',
            border: 'none',
            cursor: 'pointer',
          }}  >Guardar</Button>
          <Button color="error" variant="contained" onClick={() => {
            navigate("/video")
          }} style={{
            background: '#D8D8D8',
            fontFamily: 'WorkSans',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '16px',
            textAlign: 'center',
            textTransform: 'uppercase',
            color: '#5E5E5E',
            padding: '17px 62px',
            border: 'none',
            marginLeft: '20px',
            cursor: 'pointer',
          }}>Cancelar</Button>
        </div>

      </Box>
    </>



  )
}

export default VideoAdd