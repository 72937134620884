import { urlApi, UUIDATE } from "../utils/strings"

export interface VideoImagesI {
    imgThumb?: File
    videoContenido?: File
    imgDestacada?: File
}

const handleUploadImages = async (
    data: any, {videoContenido, imgThumb, imgDestacada}: VideoImagesI
) => {

    const url = urlApi(`/images.php`)

    if (imgThumb) {

        const formData = new FormData();
        formData.append('image', imgThumb);
        formData.append('path', "Video"); 
        
        try {
            const response = await fetch(url, {
              method: 'POST',
              body: formData,
            });
      
            const result = await response.json();
            const rute = `${"Video"}/${result.filename}`

            data.img_thumb = rute
            data.path_img_thumb = rute

          } catch (error) {
            console.error('Error:', error);
            alert('Error al subir la imagen');
          }
    }

    if(videoContenido){
        const formData = new FormData();
        formData.append('image', videoContenido);
        formData.append('path', "Video"); 
        
        try {
            const response = await fetch(url, {
              method: 'POST',
              body: formData,
            });
      
            const result = await response.json();
            const rute = `${"Video"}/${result.filename}`

            data.video_contenido = rute
            data.path_video_contenido = rute

          } catch (error) {
            console.error('Error:', error);
            alert('Error al subir la imagen');
          }
    }

    if(imgDestacada){

        const formData = new FormData();
        formData.append('image', imgDestacada);
        formData.append('path', "Video"); 
        
        try {
            const response = await fetch(url, {
              method: 'POST',
              body: formData,
            });
      
            const result = await response.json();
            const rute = `${"Video"}/${result.filename}`

            data.img_destacada = rute
            data.path_img_destacada = rute

          } catch (error) {
            console.error('Error:', error);
            alert('Error al subir la imagen');
          }
    }

    return data

}

export const addVideo = async (data: any, images: VideoImagesI) => {

    data = await handleUploadImages(data, images)

    data.id = UUIDATE()
    
    const url = urlApi(`/videos.php`)

    const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const resp = await response.json();
      if (response.ok) {
        console.log("error al guardar el video");
      } else {
        console.error('Error:', data.message);
      }


    return {
        ...data
    }

}

export const updateVideo = async (id: string, data: any, images: VideoImagesI) => {

    data = await handleUploadImages(data, images)

    
    const url = urlApi(`/videos.php`)

    const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const resp = await response.json();
      if (response.ok) {
        console.log("Actualizado");
      } else {
        console.error('Error:', data.message);
      }

    return data

}

export const deleteVideo = async (id: string) => {

    const url = urlApi(`/videos.php`)
    
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id }) // Envía el ID en el cuerpo de la solicitud
    });
  
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error en la eliminación');
    }
  
    const data = await response.json();
}

export const getVideo = async (id: string) => {

    const response = await fetch(urlApi(`/videos.php?id_video=${id}`)); 
         
    const { videos } = await response.json(); 
    
    return videos[0];

}

export const getVideos = async () => {

    const response = await fetch(urlApi('/videos.php')); 
         
    const { videos } = await response.json(); 

    return videos;

}