import { urlApi } from "../utils/strings"
import { decrypt, encrypt } from "../utils/encryp"
import { UserI, UsersI } from "../models/users";

export const addUser = async (data: any) => {

    const url = urlApi(`/registro.php`);

    const encryptedPassword = encrypt(data.password, 3);

    const userWithEncryptedPassword = {
        ...data,
        password: encryptedPassword,
    };

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user:userWithEncryptedPassword }),
    });

    const x = await response.json()
    
    return x;

}

export const getUser = async (id: string) => {

    const response = await fetch(urlApi(`/usuarios.php?id=${id}`)); 
         
    const { usuarios } = await response.json(); 
    
    let users: UserI = usuarios
    //3644;:Qry'
    users.password = decrypt(users.password, 3)

    return users;

}

export const getUsers = async () => {
    
    const response = await fetch(urlApi('/usuarios.php')); 
         
    const { usuarios } = await response.json(); 
    
    let users: UsersI = usuarios

    return users.filter(e => e.activo);

}
export const getUserSQL = async(email: string, password:string) =>{

    const url = urlApi(`/login.php`)

    const encryptedPassword = encrypt(password, 3);

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password: encryptedPassword }),
    });

    // Manejo de errores
    if (!response.ok) {
        const error = await response.json();
        return error
    }


    const {usuario, blogs, videos, descargables } = await response.json()

    usuario.blogsFavoritos = blogs
    usuario.videosFavoritos = videos
    usuario.descargablesFavoritos = descargables


    return usuario;

}

export const userExist =  async(email: string) => {

    const url = urlApi(`/usuario.php`)

    const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
    });

    const msg = await response.json();

    return msg
}

export const updateUser = async (id: string, data: any) => {

    const url = urlApi('/usuarios.php')

    const encryptedPassword = encrypt(data.password, 3);

    const userWithEncryptedPassword = {
        ...data,
        password: encryptedPassword,
    };

    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userWithEncryptedPassword)
        });

        const result = await response.json();
        
        if (response.ok) {
            console.log('Usuario actualizado:', result);
        } else {
            console.error('Error:', result.message);
        }
    } catch (error) {
        console.error('Error al actualizar:', error);
    }

    return data

}

export const deleteUser = async (id: string) => {

    const url = urlApi(`/usuarios.php`);

    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
    });

    const x = await response.json()
    
    return x;
}