import { deleteApp, initializeApp } from "firebase/app";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { firebaseConfig } from "../firebase/firebase";
import { UserI } from "../models/users";
import { addUser } from "./users";
import { UUIDATE } from "../utils/strings";

export const createUser = async (user: UserI) => {

   /* const app = initializeApp(firebaseConfig, 'loginAlterno');
    const auth = getAuth(app)

    const userCredential = await createUserWithEmailAndPassword(
        auth,
        user.email,
        user.password
    );*/
    const id = UUIDATE()
    const res = await addUser({
        ...user,
        activo: true,
        fechaRegistro: new Date(),
        id: id,
        uid: id,
    })
    console.log(res)
    //await deleteApp(app)

    return res

}